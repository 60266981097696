import React, { Fragment, Component } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import PaySpinner from 'components/Core/Payment/PaySpinner/PaySpinner';
import Button from 'components/Shared/Button/Button';
import { ApplePay, Lock, PayPal } from 'components/Shared/Icons/Icons';
const WalletFetchStatus = WALLET_MAP.FETCH_STATUS;
import deviceInfo from 'server/helpers/deviceInfo';
import ClientUtils from 'shared/clientUtils';
import { colors, breakpoints } from 'styles/cp';
import { Auth } from 'types/Auth';
import { FeatureFlags } from 'types/FeatureFlags';
import { Payment } from 'types/Payment';
import { Wallet } from 'types/Wallet';
import { TXN_MAP, WALLET_MAP, FREQ, PAYMENT_ERROR_TYPES } from 'types/constants';

type PayButtonProps = {
  intl: IntlShape;
  currency: string;
  config: object;
  wallet: Pick<Wallet, 'fetchWalletStatus'>;
  auth: Pick<Auth, 'isUserSignedIn'>;
  payment: Pick<
    Payment,
    | 'paymentError'
    | 'payPalProcessor'
    | 'paymentMethodType'
    | 'paymentStatus'
    | 'amount'
    | 'isDateScheduled'
    | 'isAutoPayOn'
    | 'isSubscriptionUpdatePaymentMethodRequired'
  >;
  ixp?: object;
  featureFlags?: FeatureFlags;
  onPayButtonClicked: (...args: any[]) => any;
  recurringInfo?: {
    recurringDescription?: string;
  };
  payMessageType?: React.ReactNode;
  payMessage?: React.ReactNode;
  isSubscription?: boolean;
  subscriptionInfo?: any;
};

class PayButton extends Component<PayButtonProps, {}> {
  private thisRef: React.RefObject<HTMLDivElement>;
  constructor(props: PayButtonProps) {
    super(props);
    this.thisRef = React.createRef();
  }
  private updateDimensions = () => {
    if (!this.thisRef.current) return;
    const bodyWidth = document.getElementsByTagName('body')[0].clientWidth;
    let extraPadding = 0;
    if (bodyWidth <= parseFloat(breakpoints.md)) {
      extraPadding = this.thisRef.current.clientHeight;
    }
    document.body.style.paddingBottom = `${extraPadding}px`;
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate() {
    this.updateDimensions();
  }
  // should block future payments where the status is success but we have a client error
  shouldBlockAfterErrorWithPaypalPayment() {
    const {
      featureFlags = {},
      payment: { paymentError, payPalProcessor, paymentMethodType, paymentStatus },
    } = this.props;
    return (
      featureFlags['appconnect-error-refresh-experience'] &&
      (paymentStatus === TXN_MAP.STATUS.ERROR || paymentStatus === TXN_MAP.STATUS.DECLINED) &&
      paymentMethodType === 'pp' &&
      payPalProcessor === 'APPCONNECT' &&
      paymentError &&
      // @ts-ignore WTF?
      paymentError.message === PAYMENT_ERROR_TYPES.PAYPAL_SUCCEED_WITH_CLIENT_ERROR
    );
  }
  shouldBlockAfterPaymentTimeout() {
    const {
      featureFlags = {},
      payment: { paymentError },
    } = this.props;
    return (
      featureFlags['payment-error-refresh-experience'] &&
      paymentError &&
      paymentError.message === PAYMENT_ERROR_TYPES.TIMEOUT
    );
  }
  isBlockPayments() {
    const { featureFlags = {} } = this.props;
    return (
      featureFlags['block-payments'] ||
      this.shouldBlockAfterPaymentTimeout() ||
      this.shouldBlockAfterErrorWithPaypalPayment()
    );
  }
  render() {
    const {
      intl,
      currency,
      payment: {
        amount,
        paymentMethodType,
        paymentStatus,
        isDateScheduled,
        isAutoPayOn,
        payPalProcessor,
        isSubscriptionUpdatePaymentMethodRequired,
      },
      wallet: { fetchWalletStatus },
      auth: { isUserSignedIn },
      recurringInfo,
      payMessageType,
      payMessage,
      featureFlags,
      isSubscription = false,
      subscriptionInfo,
    } = this.props;
    let PayButtonMessageText: React.ReactNode = (
      <FormattedMessage id="PAYNOW_PAY_BUTTON_LABEL" defaultMessage="Pay" />
    );
    if (payMessage) {
      PayButtonMessageText = payMessage;
    }
    const noPaymentMethodSelected = paymentMethodType === null;
    let isPaymentInProgress = paymentStatus === TXN_MAP.STATUS.IN_PROGRESS;
    let buttonType: 'primary' | 'paypal' | 'applepay' = 'primary';
    const isFetchingWallet =
      isUserSignedIn &&
      fetchWalletStatus === WalletFetchStatus.FETCHING &&
      (paymentMethodType === 'cc' || paymentMethodType === 'dc' || paymentMethodType === 'bank');
    const isPayPalAppConnectDisabled = ClientUtils.isPayPalAppConnectBlock(
      paymentMethodType,
      payPalProcessor,
      featureFlags
    );
    const isDisabled =
      isPaymentInProgress ||
      isFetchingWallet ||
      this.isBlockPayments() ||
      isPayPalAppConnectDisabled;
    const displayAmount = intl.formatNumber(amount, {
      style: 'currency',
      currency,
    });
    let paymentFunction = this.props.onPayButtonClicked;
    let payButtonTheme = {
      icon: (
        <Lock color={colors.white} strokeWidth={2} height={15} width={12} className="lock-icon" />
      ),
      backgroundColor: colors.green03,
      caption: {
        display: 'inline-block',
        color: colors.white,
      },
      amountToPay: {
        display: 'inline-block',
      },
      hover: {
        backgroundColor: colors.green01,
      },
      'aria-label': 'Pay Button',
    };
    let autoPayInterval;
    if (isAutoPayOn && recurringInfo && typeof recurringInfo.recurringDescription === 'string') {
      autoPayInterval = recurringInfo.recurringDescription.toLowerCase();
    }
    let subscriptionFrequency;
    if (isSubscription && subscriptionInfo) {
      const { frequency } = subscriptionInfo;
      subscriptionFrequency =
        frequency === FREQ.MONTHLY ? (
          <FormattedMessage id="MONTHLY" defaultMessage="Monthly" />
        ) : (
          <FormattedMessage id="WEEKLY" defaultMessage="Weekly" />
        );
    }
    if (paymentMethodType === 'pp') {
      if (
        typeof window === 'object' &&
        window.navigator &&
        typeof window.navigator.userAgent === 'string' &&
        deviceInfo.isEdge(window.navigator.userAgent)
      ) {
        isPaymentInProgress = false;
      }
      buttonType = 'paypal';
      payButtonTheme = {
        icon: <PayPal className="paypal-icon" />,
        backgroundColor: colors.yellow,
        caption: {
          display: 'inline-block',
          color: colors.black,
        },
        amountToPay: {
          display: 'none',
        },
        hover: {
          backgroundColor: colors.yellow,
        },
        'aria-label': 'Pay Button',
      };
    } else if (paymentMethodType === 'ap') {
      buttonType = 'applepay';
      payButtonTheme = {
        icon: (
          <ApplePay
            border={false}
            color={colors.black}
            bgColor={'colors.white'}
            height={40}
            width={60}
          />
        ),
        backgroundColor: colors.white,
        caption: {
          display: 'none',
          color: colors.black,
        },
        amountToPay: {
          display: 'none',
        },
        hover: {
          backgroundColor: colors.black,
        },
        'aria-label': 'Pay Button',
      };
    }

    return (
      <Fragment>
        {/* @ts-ignore */}
        <div className="pay-button" ref={this.thisRef}>
          <Button
            disabled={isDisabled}
            buttonType={buttonType}
            className={`pay ${noPaymentMethodSelected ? 'hidden-mobile' : ''}`}
            size="jumbo"
            width="100%"
            aria-label="Pay Button"
            aria-disabled={isDisabled}
            onClick={paymentFunction}
          >
            {/* @ts-ignore */}
            {isPaymentInProgress && <PaySpinner paymentMethodType={paymentMethodType} />}

            {!(isPaymentInProgress || paymentMethodType === 'pp') && (
              <>
                {payButtonTheme.icon}

                {isDateScheduled && !isAutoPayOn && (
                  <div style={{ display: 'inline-block' }} className="schedule-pay-button">
                    <span className="caption">
                      <FormattedMessage id="SCHEDULE" defaultMessage="Schedule" />
                    </span>
                    <span className="amount-to-pay">{displayAmount}</span>
                    <span className="caption">
                      <FormattedMessage id="PAYMENT" defaultMessage="payment" />
                    </span>
                  </div>
                )}
                {isAutoPayOn && !isSubscription && (
                  <div style={{ display: 'inline-block' }} className="schedule-pay-button">
                    <span className="caption">
                      <FormattedMessage id="AUTOPAY" defaultMessage="Autopay" />
                    </span>
                    <span className="amount-to-pay">{displayAmount}</span>
                    <span className="caption">{autoPayInterval}</span>
                  </div>
                )}

                {isAutoPayOn && isSubscription && isSubscriptionUpdatePaymentMethodRequired && (
                  <div style={{ display: 'inline-block' }} className="schedule-pay-button">
                    <span className="caption">
                      <FormattedMessage
                        id="SUBSCRIPTION_PAY_BUTTON_UPDATE"
                        defaultMessage="Update Payment Method"
                      />
                    </span>
                  </div>
                )}
                {isSubscription &&
                  subscriptionFrequency &&
                  !isSubscriptionUpdatePaymentMethodRequired && (
                    <div style={{ display: 'inline-block' }} className="schedule-pay-button">
                      <span className="caption">{PayButtonMessageText}</span>
                      <span className="amount-to-pay">{displayAmount}</span>
                      <span className="caption">{subscriptionFrequency}</span>
                    </div>
                  )}
                {!isDateScheduled && !isAutoPayOn && (
                  <div style={{ display: 'inline-block' }}>
                    <span className="caption">{PayButtonMessageText}</span>
                    <span className="amount-to-pay">{displayAmount}</span>
                    {payMessageType && <span className="caption">{payMessageType}</span>}
                  </div>
                )}
              </>
            )}

            {paymentMethodType === 'pp' && (
              <>
                <span className="caption PayPal">
                  <FormattedMessage id="PAYNOW_PAYPAL_BUTTON_LABEL" defaultMessage="Pay now with" />
                </span>
                {payButtonTheme.icon}
              </>
            )}
          </Button>
        </div>
        <style jsx>{`
          .pay-button {
            width: 100%;
            :global(.pay) {
              .caption {
                margin-left: 6px;
                display: ${payButtonTheme.caption.display};
                color: ${payButtonTheme.caption.color};
                aria-label: ${payButtonTheme['aria-label']};
              }
              .PayPal {
                font-size: 15px;
              }

              .amount-to-pay {
                margin-left: 6px;
                display: ${payButtonTheme.amountToPay.display};
              }

              .schedule-pay-button {
                @media screen and (max-width: ${breakpoints.md}) {
                  font-size: 16px;
                }
              }
            }

            :global(.hidden-mobile) {
              @media screen and (max-width: ${breakpoints.md}) {
                display: none;
              }
            }

            :global(.paypal-icon) {
              vertical-align: middle;
              width: 80px;
              height: 18px;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}
export default injectIntl(PayButton);
