import { FormikBag, withFormik } from 'formik';

import { ValidationErrorEnum } from '../validations/src/ValidationErrorEnum';

import { genericWalletField, WalletField } from './Field';
import { Errors, Form, genericForm, IWalletFormProps } from './Form';
import { validateField } from './helpers';

import {
  BankAccountTypeEnumInput,
  IBankAccountCreateData,
} from 'components/Core/WalletForms/network/src/types';
import { accountNumberValidator } from 'components/Core/WalletForms/validations/src/accountNumber';
import { accountNumberConfirmValidator } from 'components/Core/WalletForms/validations/src/accountNumberConfirm';
import { accountTypeValidator } from 'components/Core/WalletForms/validations/src/accountType';
import { institutionNumberValidator } from 'components/Core/WalletForms/validations/src/institutionNumber';
import { nameValidator } from 'components/Core/WalletForms/validations/src/name';
import { phoneValidator } from 'components/Core/WalletForms/validations/src/phone';
import { routingNumberValidator } from 'components/Core/WalletForms/validations/src/routingNumber';
import { transitNumberValidator } from 'components/Core/WalletForms/validations/src/transitNumber';

interface ICreateBankTokenFormProps
  extends IWalletFormProps<IBankAccountCreateData, IBankAccountCreateData> {
  initialValues?: IBankAccountCreateData;
  paymentMethodType?: string;
}
export const BankField: WalletField<IBankAccountCreateData> = genericWalletField;
export type BankTokenErrors = Errors<IBankAccountCreateData>;
export type FieldName = keyof IBankAccountCreateData;
export type CreateBankTokenFormikBag = FormikBag<ICreateBankTokenFormProps, IBankAccountCreateData>;
const _BankTokenForm: Form<ICreateBankTokenFormProps, IBankAccountCreateData> = genericForm;
export const BankTokenForm = withFormik<ICreateBankTokenFormProps, IBankAccountCreateData>({
  mapPropsToValues: ({ initialValues }: ICreateBankTokenFormProps) => {
    return {
      bankCode: '',
      accountNumber: '',
      accountNumberConfirm: '',
      transitNumber: '',
      institutionNumber: '',
      name: '',
      phone: '',
      padTerms: '',
      accountType: BankAccountTypeEnumInput.PERSONAL_CHECKING,
      ...initialValues,
    };
  },

  handleSubmit: async (
    values: IBankAccountCreateData,
    formikBag: FormikBag<ICreateBankTokenFormProps, IBankAccountCreateData>
  ) => {
    if (!values.phone) {
      values.phone = '0000000000';
    }
    formikBag.props.handleSubmit(values, formikBag);
  },
  validate: (values: IBankAccountCreateData, props: ICreateBankTokenFormProps): BankTokenErrors => {
    const errors: BankTokenErrors = {};
    const { paymentMethodType } = props;

    if (paymentMethodType === 'bank') {
      validateField(values, errors, 'accountNumberConfirm', (value) =>
        accountNumberConfirmValidator(value, values.accountNumber)
      );
      validateField(values, errors, 'bankCode', routingNumberValidator);
    }

    if (paymentMethodType === 'eft') {
      validateField(values, errors, 'transitNumber', transitNumberValidator);
      validateField(values, errors, 'institutionNumber', institutionNumberValidator);
      if (values['padTerms'] !== 'true') {
        errors['padTerms'] = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
      }
    }

    validateField(values, errors, 'accountType', accountTypeValidator);
    validateField(values, errors, 'accountNumber', accountNumberValidator);
    validateField(values, errors, 'name', (value) => nameValidator(value, values.accountType));

    if (values.phone) {
      const [valid, messages] = phoneValidator(values.phone);
      if (!valid && messages) {
        errors.phone = messages;
      }
    }
    return errors;
  },
})(_BankTokenForm);
