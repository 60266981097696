import React from 'react';

import Checkbox, { CheckboxChangeEventHandler } from './Checkbox';

import { colors, fontSize } from 'styles/cp';

export type LabeledCheckboxProps = {
  checked: boolean;
  onChange: CheckboxChangeEventHandler;
  type?: 'default' | 'primary';
  disabled?: boolean;
  name?: string;
  error?: boolean;
  id?: string;
  label: string | React.ReactNode;
  [propName: string]: any;
};

const LabeledCheckbox: React.FC<LabeledCheckboxProps> = (props) => {
  const { label, isInError, disabled, ...rest } = props;
  // After upgrade to react@18 should be updated to use useId hook
  const labelId = `checkbox-${performance.now()}-${Math.random()}`;

  return (
    <div className="labeledCheckbox-root">
      <span className="labeledCheckbox-checkbox">
        <Checkbox
          //@ts-ignore
          disabled={disabled}
          error={isInError}
          {...rest}
          labelId={labelId}
        />
      </span>
      <span id={labelId} className="labeledCheckbox-label">
        {label}
      </span>

      <style jsx>{`
        .labeledCheckbox-root {
          display: flex;
        }
        .labeledCheckbox-checkbox {
          flex: 0;
        }
        .labeledCheckbox-label {
          margin-left: 8px;
          font-size: ${fontSize.xs};
          color: ${colors.gray02};
          font-family: AvenirNextforINTUIT-Regular;
        }
      `}</style>
    </div>
  );
};

export default LabeledCheckbox;
