// @ts-nocheck

import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { finalizeOrderOnPayPalCommerce } from 'businessLogic/Payment/Paypal';
import PayButtonWithConsent from 'components/Core/Payment/Buttons/PayButton/PayButtonWithConsent';
import { getPaymentMethodBug } from 'components/Shared/Icons/Icons';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { createOrderOnPayPalCommerce } from 'store/payment/payPal';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'sections/PayPalCardForm';
import {
  walletTokenizationPaymentCreation,
  onFailedPayment,
  onSuccessfulPayment,
  paymentActions,
} from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { colors, fontSize } from 'styles/cp';
import { Auth } from 'types/Auth';
import { PayPalOrderIds } from 'types/BusinessLogic';
import { CompanyInfo } from 'types/CompanyInfo';
import { Config } from 'types/Config';
import { FeatureFlags } from 'types/FeatureFlags';
import { IXP } from 'types/IXP';
import { Insight } from 'types/Insight';
import { InvoiceDocument } from 'types/InvoiceDocument';
import { Payment } from 'types/Payment';
import { Sale } from 'types/Sale';
import { Wallet } from 'types/Wallet';

type PayPalCardFormProps = {
  currency: string;
  finalizeOrderOnPayPalCommerce?: (...args: any[]) => any;
  createOrderOnPayPalCommerce?: (...args: any[]) => any;
  createPayment: () => void;
  stopPayment: () => void;
  onFailedPayment: Function;
  featureFlags: FeatureFlags;
  intl: {
    formatMessage: ({ id, defaultValue }: { id: string; defaultValue?: string }) => any;
  };
  isAmexDisabled: boolean;
  onSuccessfulPayment: Function;
  domainId: string;
  token: string;
  companyLocale: string;
  invoiceId: string;
  isFullyPaid: boolean;
  customerName: string;
  isPartiallyPaid: boolean;
  balanceAmount: number;
  payment: Pick<Payment, 'amount'>;
  auth: Auth;
  config: Config;
  wallet: Wallet;
  ixp: IXP;
};

type PayPalCardFormState = {
  formValid: boolean;
  hostedFields: any;
  paymentValues: any;
};

class PayPalCardForm extends React.Component<PayPalCardFormProps, PayPalCardFormState> {
  constructor(props: PayPalCardFormProps) {
    super(props);
  }
  payPalRenderFields(paymentValues) {
    const context = this;
    const { featureFlags } = this.props;
    if (this.cvv) {
      this.cvv.innerHTML = null;
    }
    if (this.expiry) {
      this.expiry.innerHTML = null;
    }
    if (this.cardnumber) {
      this.cardnumber.innerHTML = null;
    }
    window.paypal &&
      window.paypal.HostedFields?.render({
        createOrder: () => {
          context.props.createPayment();
          return createOrderOnPayPalCommerce(paymentValues)
            .then((res) => {
              return res;
            })
            .catch((error) => {
              context.props.stopPayment();
              context.props.onFailedPayment(error, featureFlags);
              const { invoiceInfo } = error;
              splunkReporter.contextual({
                logInfo: { logLevel: 'error', logger },
                event: 'pay',
                action: 'createOrder',
                activityInfo: {
                  status: 'error',
                  paymentMethodType: 'paypalCommerce',
                  invoiceInfo,
                },
                error: {
                  message: error?.message,
                  stack: error?.stack,
                },
              });
            });
        },
        styles: {
          input: {
            'font-size': fontSize.xs,
            'font-family': 'AvenirNextforINTUIT;',
            color: '#3a3a3a',
            'font-weight': 'medium',
            transition: 'color 160ms linear',
            '-webkit-transition': 'color 160ms linear',
            border: '1px solid #333',
            frameborder: '1',
            display: 'block',
          },
          ':focus': {
            color: 'black',
          },
          border: '1px solid #333',
          '::-webkit-input-placeholder': {
            color: colors.gray04,
          },
        },
        fields: {
          cvv: {
            selector: '#cvv',
            placeholder: '123',
          },
          number: {
            selector: '#card-number',
            placeholder: '1234 5678 9000 0000',
            height: '22px',
            display: 'block',
            backgroundolor: colors.gray04,
            color: 'rgba(0, 0, 0, .87)',
            border: 'none',
            borderbottom: '1px solid rgba(0, 0, 0, .26)',
            outline: '0',
            //width: '100%',
            fontsize: fontSize.sm,
            padding: '0',
            boxshadow: 'none',
            borderradius: '0',
            position: 'relative',
            width: '500px',
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YYYY',
          },
        },
      }).then((hostedFields) => {
        let formValid = true;
        document.querySelector('#error-card-message').innerHTML = '';
        document.querySelector('#error-expiry-date-message').innerHTML = '';
        document.querySelector('#error-cvv-message').innerHTML = '';
        document.querySelector('#error-icon-wrapper-card').innerHTML = '';
        document.querySelector('#error-icon-wrapper-exp').innerHTML = '';
        document.querySelector('#error-icon-wrapper-cvv').innerHTML = '';
        document.querySelector('#card-icon').innerHTML = '';
        hostedFields.on('cardTypeChange', function (event) {
          if (event && event.cards && Array.isArray(event.cards) && event.cards.length === 1) {
            const cardIcon = getPaymentMethodBug(
              event.cards[0].niceType.toLowerCase(),
              paymentValues.config.endpoints.cdn,
              context.props.intl.formatMessage
            );
            document.querySelector('#card-icon').innerHTML =
              '<img src=' + cardIcon.props.src + '></img>';
          } else {
            document.querySelector('#card-icon').innerHTML = '';
          }
        });
        hostedFields.on('validityChange', function (event) {
          // Check if all fields are valid, then show submit button
          formValid = Object.keys(event.fields).every(function (key) {
            return event.fields[key].isValid;
          });
          const errorIcon =
            '<svg width="10"\n' +
            '                                     height="10"\n' +
            '                                     viewBox="0 0 10 10"\n' +
            '                                     xmlns="http://www.w3.org/2000/svg">\n' +
            '                                    <g fill="none" fill-Rule="evenodd">\n' +
            '                                        <circle cx="5" cy="5" r="5" fill="#d52b1e"/>\n' +
            '                                        <text fill="#FFF" fontFamily="AvenirNextforINTUIT-Demi, AvenirNext forINTUIT" fontSize="6" fontWeight="500" letterSpacing="0">\n' +
            '                                            <tspan x="2.36" y="7.692">!</tspan>\n' +
            '                                        </text>\n' +
            '                                    </g>\n' +
            '                                </svg>';
          if (event.fields['number'].isValid || event.fields['number'].isEmpty) {
            document.querySelector('#error-card-message').innerHTML = '';
            document.querySelector('#error-icon-wrapper-card').innerHTML = '';
          } else {
            document.querySelector('#error-card-message').innerHTML =
              'Please enter a valid credit card number.';
            document.querySelector('#error-icon-wrapper-card').innerHTML = errorIcon;
            formValid = false;
          }
          if (event.fields['cvv'].isValid || event.fields['cvv'].isEmpty) {
            document.querySelector('#error-cvv-message').innerHTML = '';
            document.querySelector('#error-icon-wrapper-cvv').innerHTML = '';
          } else {
            document.querySelector('#error-cvv-message').innerHTML =
              'Please check your CVV number.';
            document.querySelector('#error-icon-wrapper-cvv').innerHTML = errorIcon;
            formValid = false;
          }
          if (event.fields['expirationDate'].isValid || event.fields['expirationDate'].isEmpty) {
            document.querySelector('#error-expiry-date-message').innerHTML = '';
            document.querySelector('#error-icon-wrapper-exp').innerHTML = '';
          } else {
            document.querySelector('#error-expiry-date-message').innerHTML =
              "Please check your card's expiry date.";
            document.querySelector('#error-icon-wrapper-exp').innerHTML = errorIcon;
            formValid = false;
          }
          if (
            context.props.isAmexDisabled &&
            event.cards &&
            Array.isArray(event.cards) &&
            event.cards.length === 1 &&
            event.cards[0].niceType === 'American Express'
          ) {
            document.querySelector('#error-card-message').innerHTML =
              'American Express credit cards are not supported. Please use a different card.';
            document.querySelector('#error-icon-wrapper-card').innerHTML = errorIcon;
            formValid = false;
          }
          context.setState({ formValid });
        });
        context.setState({ hostedFields, paymentValues });
      });
  }
  setErrorLabels() {
    const errorIcon =
      '<svg width="10"\n' +
      '                                     height="10"\n' +
      '                                     viewBox="0 0 10 10"\n' +
      '                                     xmlns="http://www.w3.org/2000/svg">\n' +
      '                                    <g fill="none" fillRule="evenodd">\n' +
      '                                        <circle cx="5" cy="5" r="5" fill="#d52b1e"/>\n' +
      '                                        <text fill="#FFF" fontFamily="AvenirNextforINTUIT-Demi, AvenirNext forINTUIT" fontSize="6" fontWeight="500" letterSpacing="0">\n' +
      '                                            <tspan x="2.36" y="7.692">!</tspan>\n' +
      '                                        </text>\n' +
      '                                    </g>\n' +
      '                                </svg>';
    document.querySelector('#error-card-message').innerHTML = 'Please check your card details.';
    document.querySelector('#error-cvv-message').innerHTML = 'Please check your CVV number.';
    document.querySelector('#error-expiry-date-message').innerHTML =
      "Please check your card's expiry date.";
    document.querySelector('#error-icon-wrapper-card').innerHTML = errorIcon;
    document.querySelector('#error-icon-wrapper-exp').innerHTML = errorIcon;
    document.querySelector('#error-icon-wrapper-cvv').innerHTML = errorIcon;
  }
  hostedSubmitPayment() {
    const hostedFields = this.state.hostedFields;
    const paymentValues = this.state.paymentValues;
    const formValid = this.state.formValid;
    const { featureFlags } = this.props;
    const context = this;
    if (
      hostedFields.getState().fields.cvv.isEmpty ||
      hostedFields.getState().fields.expirationDate.isEmpty ||
      hostedFields.getState().fields.number.isEmpty
    ) {
      this.setErrorLabels();
      event.preventDefault();
    } else if (!formValid) {
      this.setErrorLabels();
      event.preventDefault();
    } else {
      event.preventDefault();
      hostedFields
        .submit({ contingencies: ['3D_SECURE'] })
        .then((payload: PayPalOrderIds) => {
          if (payload.liabilityShifted === undefined) {
            // Handle no 3D Secure contingency passed scenario
            payload.notAllowedPayPalCardPayment = false;
          } else if (payload.liabilityShifted === false) {
            // Handle buyer failed or skipped 3D Secure use-case
            payload.notAllowedPayPalCardPayment = true;
          }
          finalizeOrderOnPayPalCommerce(payload, paymentValues, featureFlags)
            .then((res) => {
              context.props.onSuccessfulPayment({ result: res });
              return res;
            })
            .catch((error) => {
              context.props.stopPayment();
              context.props.onFailedPayment(error.message, featureFlags);
              const { invoiceInfo } = error;
              splunkReporter.contextual({
                logInfo: { logLevel: 'error', logger },
                event: 'pay',
                action: 'onApprove',
                activityInfo: {
                  status: 'error',
                  paymentMethodType: 'paypalCommerce',
                  invoiceInfo,
                },
                error: {
                  message: error?.message,
                  stack: error?.stack,
                },
              });
            });
        })
        .catch(
          (error: { message: string; stack?: string | React.ErrorInfo; invoiceInfo: string }) => {
            const { featureFlags } = this.props;
            context.props.stopPayment();
            context.props.onFailedPayment(error.message, featureFlags);
            const { invoiceInfo } = error;
            splunkReporter.contextual({
              logInfo: { logLevel: 'error', logger },
              event: 'pay',
              action: 'general',
              activityInfo: {
                status: 'error',
                paymentMethodType: 'paypalCommerce',
                invoiceInfo,
              },
              error: {
                message: error?.message,
                stack: error?.stack,
              },
            });
          }
        );
    }
  }
  componentDidMount() {
    const {
      domainId,
      token,
      companyLocale,
      invoiceId,
      currency,
      isFullyPaid,
      customerName,
      featureFlags,
      isPartiallyPaid,
      openInvoices,
      balanceAmount,
      payment,
      auth,
      config,
      wallet,
    } = this.props;
    this.payPalRenderFields({
      payment,
      auth,
      config,
      wallet,
      domainId,
      token,
      companyLocale,
      customerName,
      featureFlags,
      invoiceId,
      currency,
      isFullyPaid,
      isPartiallyPaid,
      openInvoices,
      balanceAmount,
    });
  }
  componentDidUpdate(prevProps: PayPalCardFormProps) {
    if (prevProps.payment.amount !== this.props.payment.amount) {
      const {
        domainId,
        token,
        companyLocale,
        invoiceId,
        currency,
        isFullyPaid,
        isPartiallyPaid,
        customerName,
        featureFlags,
        openInvoices,
        balanceAmount,
        payment,
        auth,
        config,
        wallet,
      } = this.props;
      this.payPalRenderFields({
        domainId,
        token,
        companyLocale,
        invoiceId,
        currency,
        isFullyPaid,
        customerName,
        featureFlags,
        isPartiallyPaid,
        openInvoices,
        balanceAmount,
        payment,
        auth,
        config,
        wallet,
      });
    }
  }
  render() {
    return (
      <Fragment>
        <form
          id="paypalcardform"
          className="paypalcardform"
          onSubmit={() => this.hostedSubmitPayment()}
          ref={(ref) => (this.form = ref)}
        >
          <div className="form-row">
            <label className="hosted-field--label" htmlFor="card-number">
              <span>Card number</span>
            </label>
            <div className="wrapper">
              <div
                id="card-number"
                className="hosted-field"
                ref={(ref) => (this.cardnumber = ref)}
              />
              <div className="bug-wrapper">
                <span className="helper" id="card-icon" />
              </div>
            </div>
            <div className="field-error-message-wrapper" id="error-wrapper">
              <div className="error-icon-wrapper" id="error-icon-wrapper-card" />
              <span className="error-message">
                <label id="error-card-message" />
              </span>
            </div>
          </div>
          <div className="flex second-form-row">
            <div className="exp-date">
              <label className="hosted-field--label" htmlFor="expiration-date">
                <span>Exp Date</span>
              </label>
              <div
                id="expiration-date"
                className="exp-date-field"
                ref={(ref) => (this.expiry = ref)}
              />
              <div className="field-error-message-wrapper">
                <div className="error-icon-wrapper" id="error-icon-wrapper-exp" />
                <span className="error-message">
                  <label id="error-expiry-date-message" />
                </span>
              </div>
            </div>
            <div className="cvv">
              <label className="hosted-field--label" htmlFor="cvv">
                <span>CVV code</span>
              </label>
              <div id="cvv" className="cvv-date-field" ref={(ref) => (this.cvv = ref)} />
              <div className="field-error-message-wrapper">
                <div className="error-icon-wrapper" id="error-icon-wrapper-cvv" />
                <span className="error-message">
                  <label id="error-cvv-message" />
                </span>
              </div>
            </div>
          </div>
          <PayButtonWithConsent
            currency={this.props.currency}
            config={this.props.config}
            wallet={this.props.wallet}
            auth={this.props.auth}
            payment={this.props.payment}
            ixp={this.props.ixp}
            onPayButtonClicked={() => this.hostedSubmitPayment()}
          />
          <div id="payments-sdk__contingency-lightbox" />
        </form>

        <style jsx>{`
          .paypalcardform {
            margin-top: -5px;
          }
          .form-row {
            margin-bottom: 15px;
          }
          .exp-date-field {
            height: 38px;
            display: block;
            background-color: transparent;
            color: #babec5;
            border: 1px solid rgba(0, 0, 0, 0.26);
            border-bottom: 1px solid rgba(0, 0, 0, 0.26);
            outline: 0;
            font-size: 14px;
            padding-top: 8px;
            padding-bottom: 8px;

            padding-left: 16px;
            box-shadow: none;
            border-radius: 0;
            position: relative;
            font-family: AvenirNextforINTUIT-Demi;
            border-radius: 4px;
          }
          .cvv {
            margin-right: 0px;
            margin-left: 20px;
          }
          .cvv-date-field {
            height: 38px;
            display: block;
            background-color: transparent;
            color: #babec5;
            border: 1px solid rgba(0, 0, 0, 0.26);
            border-bottom: 1px solid rgba(0, 0, 0, 0.26);
            outline: 0;
            font-size: 14px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 16px;
            box-shadow: none;
            border-radius: 0;
            position: relative;
            font-family: AvenirNextforINTUIT-Demi;
            border-radius: 4px;
          }

          .field-error-message-wrapper {
            line-height: 14px;

            .error-icon-wrapper {
              position: absolute;
            }

            .error-message {
              display: block;
              padding-left: 12px;
              font-family: AvenirNextforINTUIT-Medium;
            }
          }
          .hosted-field--label {
            transform: translateY(0.4em);
            font-size: 12px;
            font-family: AvenirNextforINTUIT-Demi;
            line-height: 20px;
            transition: all 0.15s ease-out;
            display: block;
            width: 100%;
            font-weight: 10000;
            overflow: hidden;
            margin-bottom: 4px;
            &.label-float {
              text-overflow: ellipsis;
              color: #2196f3;
              transition: all 0.15s ease-out;
            }
            &.filled {
              @extend .label-float;
              color: rgba(0, 0, 0, 0.54);
            }
            &.invalid {
              @extend .label-float;
              color: #f44336;
            }
          }
          .hosted-field {
            height: 38px;
            display: block;
            background-color: transparent;
            color: #babec5;
            outline: 0;
            //width: 100%;
            font-size: 14px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-right: 50px;
            padding-left: 16px;
            box-shadow: none;
            border-radius: 0;
            position: relative;
            width: 520px;
            font-family: AvenirNextforINTUIT-Demi;
            border-radius: 4px;
          }
          .second-form-row {
            margin-bottom: 30px;
          }
          .bug-wrapper {
            white-space: nowrap;
            text-align: center;
            height: 18px;
            margin: 8px;

            .helper {
              display: inline-block;

              :global(img) {
                height: 18px;
              }
            }
          }
          .wrapper {
            display: flex;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.26);
            border-bottom: 1px solid rgba(0, 0, 0, 0.26);
          }
          .input-wrapper {
            width: 100%;
            padding: 6px 0 6px 8px;
          }

          .track {
            stroke: gray;
          }

          .path {
            stroke-dasharray: 1, 200;
            animation: dash 4s cubic-bezier(0.15, 0, 0, 1) infinite, color 4s linear infinite;
            stroke-linecap: round;
          }

          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }

          @keyframes dash {
            0% {
              stroke-dasharray: 1, 200;
              stroke-dashoffset: 0;
            }
            50% {
              stroke-dasharray: 126, 200;
              stroke-dashoffset: 0;
            }
            100% {
              stroke-dasharray: 126, 200;
              stroke-dashoffset: -126;
            }
          }

          @keyframes color {
            0% {
              stroke: darkGreen;
            }
            50% {
              stroke: green;
            }
            100% {
              stroke: green;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}
function mapStateToProps(store: {
  invoiceDocument: InvoiceDocument;
  sale: Sale;
  insight: Insight;
  companyInfo: CompanyInfo;
  config: Config;
  payment: Payment;
  wallet: Wallet;
  auth: Auth;
  featureFlags: FeatureFlags;
}) {
  const {
    invoiceDocument,
    sale,
    insight,
    companyInfo,
    config,
    payment,
    wallet,
    auth,
    featureFlags,
  } = store;
  return {
    invoiceAmount: saleSelectors.amountSelector(sale),
    transactionType: saleSelectors.typeSelector(sale),
    invoiceDueDate: saleSelectors.dueDateSelector(sale),
    offerId: insightSelectors.offerIdSelector(insight),
    paymentDetailsMessage: saleSelectors.paymentDetailsMessageSelector(sale),
    domainId: saleSelectors.domainIdSelector(sale, auth),
    token: insightSelectors.tokenSelector(insight),
    companyLocale: companyInfoSelectors.localeSelector(companyInfo),
    invoiceId: saleSelectors.localIdSelector(sale),
    currency: saleSelectors.currencySelector(sale),
    isFullyPaid: insightSelectors.isFullyPaidSelector(insight),
    isPartiallyPaid: insightSelectors.isPartiallyPaidSelector(insight),
    openInvoices: insightSelectors.openInvoicesSelector(insight),
    isAmexDisabled: insight.isAmexDisabled,
    balanceAmount: saleSelectors.balanceSelector(sale),
    customerName: saleSelectors.customerNameSelector(sale),
    invoiceDocument,
    config,
    featureFlags,
    payment,
    wallet,
  };
}
export default injectIntl(
  connect(mapStateToProps, {
    createPayment: walletTokenizationPaymentCreation,
    onFailedPayment,
    stopPayment: paymentActions.cancelPayment,
    onSuccessfulPayment,
  })(PayPalCardForm)
);
