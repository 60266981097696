import React from 'react';

import LabeledCheckbox from './LabeledCheckbox';

import InputErrorMessage from 'components/Shared/Inputs/InputErrorMessage';
import LabeledInput from 'components/Shared/Inputs/LabeledInput';

export interface Props {
  label: string | React.ReactNode;
  className?: string;
  validationError?: number | boolean;
  translator: Function;
  validationValues?: {};
  setFieldErrorMessage?: (errorComponent: any) => any;
  [propName: string]: any;
  checked?: boolean;
  type?: 'checkbox' | 'text';
}
const ValidatedLabeledInput: React.FC<Props> = (props) => {
  const {
    validationError,
    translator,
    validationValues = {},
    setFieldErrorMessage,
    type,
    checked = false,
    ...rest
  } = props;

  const isInError = typeof validationError === 'number';
  // @ts-ignore
  const getErrorComponent = () => {
    const errorComponent = (isInError && (
      <InputErrorMessage id={translator(validationError)} values={validationValues} />
    )) || <></>;

    if (setFieldErrorMessage) {
      // Passing error component to parent and not displaying it in ValidatedLabeledInput component
      setFieldErrorMessage(errorComponent);
    } else {
      // Displaying the error component in ValidatedLabeledInput component
      return errorComponent;
    }
  };

  return (
    <div>
      {type === 'checkbox' ? (
        //@ts-ignore
        <LabeledCheckbox {...rest} checked={checked} isInError={isInError} />
      ) : (
        <>
          <LabeledInput {...rest} isInError={isInError} />
          {getErrorComponent()}
        </>
      )}
      <style jsx>{`
        .field-error-message-wrapper {
          line-height: 14px;

          .error-icon-wrapper {
            position: absolute;
          }

          .error-message {
            display: block;
            padding-left: 12px;
            font-family: AvenirNextforINTUIT-Medium;
          }
        }
      `}</style>
    </div>
  );
};

export default ValidatedLabeledInput;
